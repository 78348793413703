// Chakra imports
import { Box } from "@chakra-ui/react";
import DashboardContent from "components/DashboardContent";

export default function UserReports() {
  return (
    <Box>
      <DashboardContent />
    </Box>
  );
}
