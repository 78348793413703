import { Box, Image } from "@chakra-ui/react";
import React from "react";

const DashboardContent = ({ children, textStyles, ...rest }) => {
  const today = new Date();
  const isValentinesDay = today.getDate() === 14 && today.getMonth() === 1;

  return (
    <>
      <Box
        w={"100%"}
        h={"90vh"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        {...rest}
      >
        {isValentinesDay && (
          <Image
            src="/images/ghost_dog_valentines.jpg"
            alt="Ghost Dog"
            maxHeight={"100%"}
          />
        )}
      </Box>
    </>
  );
};

export default DashboardContent;
