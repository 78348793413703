import { useContext, useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Client } from "api/axios";
import { Button, HStack, useDisclosure, VStack } from "@chakra-ui/react";
import { MdDownload, MdOutlineStarHalf } from "react-icons/md";

import Notes from "components/Notes";
import { GroupingContainer } from "components/groupingContainer/GroupingContainer";
import {
  CsvDownloadButton,
  jsonToCsvTransformer,
} from "components/csvDownloadButton";
import FtmsBreachesTable from "components/ftmsBreachesTable";
import ConfirmationModal from "components/modals/Confirmation";
import { toast } from "react-toastify";
import { ALERT_MESSAGE } from "variables/message";
import FeatureSwitchContext from "contexts/FeatureSwitchProvider";

export default function ExpandedRow({ row }) {
  const queryClient = useQueryClient();
  const { featureSwitches } = useContext(FeatureSwitchContext);

  const postTrustpilotReview = async ({ id }) => {
    await Client(true).post(`/api/client/${id}/trustpilot-review`, {});
    queryClient.invalidateQueries({
      queryKey: [`/api/accounts`],
    });
  };

  const sendTrustpilotReview = useMutation({
    mutationFn: postTrustpilotReview,
    onSuccess: async () => {
      toast.success(ALERT_MESSAGE.PASS_ACTION_SUCCESS);
      await queryClient.invalidateQueries({ queryKey: ["passes"] });
    },
  });
  const accountId = row.original.id;
  const tpModal = useDisclosure();

  const addNoteForAccount = (message) => {
    const client = Client(true);
    return client.post(`/api/trade-accounts/${accountId}/notes`, { message });
  };
  const [notesPagination, setNotesPagination] = useState({
    limit: 10,
    page: 1,
  });

  const deleteNoteForAccount = (noteId) => {
    const client = Client(true);
    client.delete(`/api/notes/${noteId}`).then(refetchNotes);
  };

  const [notesFilters, setNotesFilters] = useState({ filter: "" });
  const getNotesForAccount = async () => {
    const client = Client(true);
    const queryParameters = new URLSearchParams({
      page: notesPagination.page,
      limit: notesPagination.limit,
    });
    if (notesFilters && notesFilters.filter) {
      queryParameters.append("filter", notesFilters.filter);
    }
    if (
      notesFilters?.filterActionsSelected &&
      notesFilters?.filterActionsSelected.length
    ) {
      notesFilters.filterActionsSelected.forEach((element) => {
        queryParameters.append("filterAction", element);
      });
    }

    const result = await client.get(
      `/api/trade-accounts/${accountId}/notes?${queryParameters.toString()}`
    );
    return result.data;
  };

  const {
    data: notesData,
    isLoading: notesLoading,
    refetch: refetchNotes,
  } = useQuery({
    queryKey: [
      "notes",
      accountId,
      notesFilters,
      notesPagination.page,
      notesPagination.limit,
    ],
    queryFn: () => getNotesForAccount(),
  });

  const { mutate: addNote } = useMutation({
    mutationFn: addNoteForAccount,
    onSuccess: refetchNotes,
  });

  const { mutate: deleteNote } = useMutation({
    mutationFn: deleteNoteForAccount,
    onSuccess: refetchNotes,
  });

  useEffect(() => {
    if (accountId) {
      refetchNotes();
    }
  }, [accountId, refetchNotes]);

  useEffect(() => {
    if (!notesData || !notesData.docs) return;
    const { docs, ...rest } = notesData;

    setNotesPagination({ ...rest });
  }, [notesData]);
  const timestamp = new Date().toISOString();
  const url = `/api/trade-accounts/${accountId}/trades`;
  const fileName = `account-${accountId}-trades-${timestamp}.csv`;

  return (
    <HStack w="100%" alignItems={"flex-start"}>
      <VStack w="100%">
        <GroupingContainer title="FTMS Breaches">
          <FtmsBreachesTable data={row.original.ftmsBreaches} />
        </GroupingContainer>
        <Notes
          notes={notesData?.docs || []}
          loading={notesLoading}
          addNote={addNote}
          deleteNote={deleteNote}
          flex="1"
          alignSelf="stretch"
          pagination={notesPagination}
          onPaginationChange={({ page, limit }) => {
            setNotesPagination({ ...notesPagination, page, limit });
          }}
          onFilterChange={setNotesFilters}
          filterActions={[
            {
              title: "Show System Emails",
              value: "emails",
              selected: true,
            },
            {
              title: "Show Client activity",
              value: "client",
            },
            {
              title: "Show System operations",
              value: "system",
            },
          ]}
        />
      </VStack>

      <GroupingContainer title="Actions">
        {accountId && (
          <CsvDownloadButton
            url={url}
            fileName={fileName}
            transformer={jsonToCsvTransformer}
            type="text/csv"
            size="sm"
            leftIcon={<MdDownload />}
          >
            Trades
          </CsvDownloadButton>
        )}
        {accountId && featureSwitches.trustpilot?.enabled && (
          <Button
            url={url}
            onClick={() => {
              tpModal.onOpen();
            }}
            size="sm"
            leftIcon={<MdOutlineStarHalf />}
          >
            Trustpilot Review
          </Button>
        )}
      </GroupingContainer>
      <ConfirmationModal
        header={{ children: "Trustpilot Service Review" }}
        body={{
          children: [
            `Are you sure you want to send Trustpilot service review?`,
          ],
        }}
        footer={{
          action: {
            text: "Send",
            props: { colorScheme: "green" },
            onConfirmation: () => {
              sendTrustpilotReview.mutate({ id: row.original.client.id });
            },
          },
        }}
        disclosure={tpModal}
      />
    </HStack>
  );
}
